import { PrismicRichText } from "@prismicio/react";
import { graphql, HeadFC, PageProps } from "gatsby";
import React from "react";
import Footer from "../components/footer";
import HtmlText from "../components/htmlText";
import Seo from "../components/seo";

const Terms: React.FC<PageProps<Queries.TermsQuery>> = ({ data }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="container mx-auto py-16">
        <HtmlText text={data.prismicTerms?.data.terms_text?.richText} />
      </main>
      <Footer footer={data} activePage={data.prismicTerms!} />
    </div>
  );
};

export const query = graphql`
  query Terms($lang: String) {
    ...Footer
    ...Global

    prismicTerms(lang: { eq: $lang }) {
      id
      lang
      type
      url
      data {
        terms_text {
          richText
        }
      }
    }
  }
`;

export default Terms;

export const Head: HeadFC = () => <Seo />;
